@import "reboot.css";


$img-src: "/img/";

$masterhead-bg-gradient: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.0) 30%, rgba(0, 0, 0, 0.0) 85%, #000 100%);

:root {
  --scrollbar-width: 20px;
}

body {
  color: white;
  min-height: calc(100vh - var(--scrollbar-width));

  background: linear-gradient(to bottom, #1e2125 calc(100% - 9rem), #000 100%) no-repeat scroll center;
}

a {
  color: #acc6c7;
  overflow-wrap: break-word;

  &:hover, &:focus {
    color: #75b0b2;
  }
}

#mainNav {

  transition: background-color .5s ease-in-out;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;

  .brand {
    display: none;
    margin: auto 0px;
    padding: 0px 1.5rem;
    font-size: 1.5rem;
    font-weight: 500;
  }

  ul {
    list-style-type: none;
    display: flex;
    margin: 0px 0px 0px auto;
  }

  a {
    padding: 2rem 1.5rem;
    display: block;
    color: white;
    text-decoration: none;

    &:hover, &:focus {
      text-decoration: underline;
    }
  }
}

html:not([data-scroll='0']) {
  #mainNav {
    background-color: #fff;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);

    a {
      color: rgba(0, 0, 0, 0.9);
    }

    .brand {
      display: block;
    }
  }
}

@media (max-width: 767px) {
  #mainNav {
    background-color: #fff;
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);

    a {
      color: rgba(0, 0, 0, 0.9);
    }

    .brand {
      display: block;
    }
  }

}

.content {
  padding: 6rem 1rem 1rem 1rem;
}

.box {
  background-color: #333333;
  text-align: justify;
  overflow-wrap: break-word;

  box-shadow: 1em 1em 2em #000000;
  padding: 1rem;

  h1 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  p, ul {
    font-weight: 500;
  }

  ul {
    list-style-type: disclosure-closed;
  }
}

.rtl {
  unicode-bidi: bidi-override;
  direction: rtl;
}

#head {

  min-height: 100vh;

  @media (orientation: landscape) {
    .masthead {
      height: calc(100vw * 0.5625);
    }
    @media (max-width: 812px) {
      .masthead {
        background: $masterhead-bg-gradient, url($img-src + "bg/Mountain-Background_812.jpg");
      }
    }
    @media (min-width: 813px) and (max-width: 1024px) {
      .masthead {
        background: $masterhead-bg-gradient, url($img-src + "bg/Mountain-Background_1024.jpg");
      }
    }
    @media (min-width: 1025px) and (max-width: 1366px) {
      .masthead {
        background: $masterhead-bg-gradient, url($img-src + "bg/Mountain-Background_1366.jpg");
      }
    }
    @media (min-width: 1367px) and (max-width: 1920px) {
      .masthead {
        background: $masterhead-bg-gradient, url($img-src + "bg/Mountain-Background_1900.jpg");
      }
    }
    @media (min-width: 1921px) {
      .masthead {
        background: $masterhead-bg-gradient, url($img-src + "bg/Mountain-Background.jpg");
      }
    }
  }

  @media (orientation: portrait) {
    .masthead {
      height: calc(100vw * 1.77778);
    }

    @media (max-width: 375px) {
      .masthead {
        background: $masterhead-bg-gradient, url($img-src + "bg/Mountain-Background_h_375.jpg");
      }
    }
    @media (min-width: 376px) and (max-width: 768px) {
      .masthead {
        background: $masterhead-bg-gradient, url($img-src + "bg/Mountain-Background_h_768.jpg");
      }
    }
    @media (min-width: 769px) and (max-width: 1024px) {
      .masthead {
        background: $masterhead-bg-gradient, url($img-src + "bg/Mountain-Background_h_1024.jpg");
      }
    }
    @media (min-width: 1025px) and (max-width: 1200px) {
      .masthead {
        background: $masterhead-bg-gradient, url($img-src + "bg/Mountain-Background_h_1200.jpg");
      }
    }
    @media (min-width: 1201px) and (max-width: 1440px) {
      .masthead {
        background: $masterhead-bg-gradient, url($img-src + "bg/Mountain-Background_h_1440.jpg");
      }
    }
    @media (min-width: 1441px) {
      .masthead {
        background: $masterhead-bg-gradient, url($img-src + "bg/Mountain-Background_h.jpg");
      }
    }
  }

  .masthead h1 {
    text-align: center;
    font-size: calc(2rem + 0.9vw);
    line-height: calc(2rem + 0.9vw);
    letter-spacing: calc(0.3rem + 0.5vw);
    padding-top: 45vh;
    color: #ffffff80;
  }

  .masthead {
    position: relative;
    width: 100%;
    max-height: 100vh;

    background-position: center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
  }

  .masterhead-spacer {
    height: 9rem;
    background: linear-gradient(to bottom, #000 0%, #1e2125 100%);
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
  }

}

#photos {

  padding: 5.8rem 0;

  .carousel {
    display: grid;
    grid-template-columns: 3rem auto 3rem;
    gap: 0px 0px;

    .left {
      grid-column-start: 1;
      grid-column-end: 2;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
    }

    .right {
      grid-column-start: 3;
      grid-column-end: 4;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    }

    .left, .right {
      grid-row: 1;

      z-index: 2;

      cursor: pointer;

      display: inline-block;
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: 100% 100%;
    }

    .images {
      grid-column-start: 1;
      grid-column-end: 4;
      grid-row: 1;
      margin: auto;

      .image {
        position: relative;
        object-fit: contain;
        height: calc(100vh - 6rem);
        width: calc(100vw - var(--scrollbar-width));
        overflow: hidden;

        img {
          bottom: 0;
          left: 0;
          margin: auto;
          max-height: 100%;
          max-width: 100%;
          position: absolute;
          right: 0;
          top: 0;
          box-shadow: 1em 1em 2em #000000;
          z-index: 1;
        }

        &.image-prev img {
          transform: translateX(-105vw);
        }

        &.image-next img {
          transform: translateX(105vw);
        }

        $animation-speed: 1s;

        .moveOutPrev {
          animation: moveOutPrevFrame $animation-speed ease-out forwards;
        }

        @keyframes moveOutPrevFrame {
          from {
            transform: translateX(0vw)
          }
          to {
            transform: translateX(105vw)
          }
        }

        .moveInPrev {
          animation: moveInPrevFrame $animation-speed ease-out forwards;
        }

        @keyframes moveInPrevFrame {
          from {
            transform: translateX(-105vw)
          }
          to {
            transform: translateX(0vw)
          }
        }

        .moveOutNext {
          animation: moveOutNextFrame $animation-speed ease-out forwards;
        }

        @keyframes moveOutNextFrame {
          from {
            transform: translateX(0vw)
          }
          to {
            transform: translateX(-105vw)
          }
        }

        .moveInNext {
          animation: moveInNextFrame $animation-speed ease-out forwards;
        }

        @keyframes moveInNextFrame {
          from {
            transform: translateX(105vw)
          }
          to {
            transform: translateX(0vw)
          }
        }
      }

    }
  }
}


#about {
  margin: 2rem 1rem 1rem 1rem;
  padding: 5.8rem 0;
  min-height: 100vh;

  .spacer {
    height: 3rem;
  }

  .frame {
    margin: auto;
    max-width: 750px;
    display: grid;
    gap: 3rem 3rem;
    color: #fffcf4;
    align-items: center;

    @media (max-width: 600px) {
      &.ileft, &.iright {
        grid-template-rows: [img] auto [text] auto;
      }

      .text {
        grid-row-start: text;
        grid-row-end: text;
      }

      img {
        grid-row-start: img;
        grid-row-end: img;
      }
    }

    @media (min-width: 601px) {
      &.ileft {
        grid-template-columns: 1fr 2fr;
      }
      &.iright {
        grid-template-columns: 2fr 1fr;
      }

      .text {
        height: 100%;
      }
    }

    img {
      width: 100%;
      box-shadow: 1em 1em 2em #000000;
    }
  }

}
